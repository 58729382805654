<script setup lang="ts">
import { ref, computed, onMounted, nextTick, onBeforeUnmount, watch, useAttrs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import { DistributionType } from '@/http/models/Establishment'

defineOptions({
  inheritAttrs: false,
})

const attrs = useAttrs()

type Props = {
  modelValue: number
  size?: string
  variant?: string
  distributionTypes?: number[]
  hasTooltip?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  variant: 'default',
  // TODO remove this default value, DistributionTypeDropdown should be wrapped in a v-if if establishment is null
  distributionTypes: () => [DistributionType.Delivery, DistributionType.TakeAway],
  hasTooltip: false,
})

const emit = defineEmits(['update:modelValue', 'toggle'])

const { t } = useI18n()
const store = useStore()

const container = ref<HTMLElement | null>(null)
const pillBackground = ref<HTMLElement | null>(null)
const onLocationPill = ref<HTMLElement | null>(null)
const deliveryPill = ref<HTMLElement | null>(null)
const takeAwayPill = ref<HTMLElement | null>(null)
const distTooltip = ref<HTMLElement | null>(null)

const showTooltip = ref(false)

const distributionType = computed(() => store.getters['session/distributionType'])

const addTooltip = () => {
  showTooltip.value = true

  if (deliveryPill.value === null || takeAwayPill.value === null) {
    return
  }

  if (distributionType.value === DistributionType.Delivery) {
    deliveryPill.value.classList.add('glow')
  } else if (distributionType.value === DistributionType.TakeAway) {
    takeAwayPill.value.classList.add('glow')
  }

  setTimeout(() => {
    if (deliveryPill.value === null || takeAwayPill.value === null || distTooltip.value === null) return

    deliveryPill.value.classList.remove('glow')
    takeAwayPill.value.classList.remove('glow')
    if (distributionType.value === DistributionType.Delivery) {
      deliveryPill.value.classList.add('glowout')
    } else if (distributionType.value === DistributionType.TakeAway) {
      takeAwayPill.value.classList.add('glowout')
    }
  }, 6000)

  setTimeout(() => {
    if (deliveryPill.value === null || takeAwayPill.value === null || distTooltip.value === null) return
    distTooltip.value?.classList.add('fadeout')
  }, 4000)

  //Remove all animations
  setTimeout(() => {
    if (deliveryPill.value === null || takeAwayPill.value === null || distTooltip.value === null) return
    deliveryPill.value.classList.remove('glowout')
    takeAwayPill.value.classList.remove('glowout')
    showTooltip.value = false
  }, 10000)

  store.dispatch('session/setDistributionTooltipShown',true)

}

function movePillBackground() {
  nextTick(() => {
    let element = onLocationPill.value

    if (props.modelValue === DistributionType.Delivery) {
      element = deliveryPill.value
    } else if (props.modelValue === DistributionType.TakeAway) {
      element = takeAwayPill.value
    }

    // the ref starts off with a string value
    if (!element || typeof element === 'string') {
      return
    }

    const boundingBox = element.getBoundingClientRect()
    const containerBoundingBox = container.value?.getBoundingClientRect()

    const filtered = ['width', 'height']

    filtered.forEach((prop) => {
      if (boundingBox[prop] > 0) pillBackground.value.style[prop] = `${boundingBox[prop]}px`
    })

    if (boundingBox.left) pillBackground.value.style.left = `${(boundingBox.left - containerBoundingBox.left)}px`
  })
}

async function changeDistribution(event: Event, type: number) {
  if (props.modelValue === type) return

  emit('toggle', event, type)

  if (event.defaultPrevented) return

  emit('update:modelValue', type)
}

const onLocationIdentifier = computed(() => store.getters['session/onLocationIdentifier'])

const doesDeliveriesAndTakeAways = computed(() => props.distributionTypes.includes(DistributionType.Delivery) && props.distributionTypes.includes(DistributionType.TakeAway))

const shouldShowTooltip = () => {
  const currentEstablishment = store.getters['establishment/establishment']
  const available = (distributionType, establishment = currentEstablishment) =>
    establishment?.distributionTypes?.includes(distributionType)

  if (store.getters['session/distributionTooltipShown'] === true) return false

  return available(DistributionType.Delivery) && available(DistributionType.TakeAway) && props.hasTooltip
}

watch(() => props.modelValue, val => {
  movePillBackground()
})

let resizeObserver: ResizeObserver | null = null

const shouldShow = shouldShowTooltip()

onMounted(() => {
  resizeObserver = new ResizeObserver(() => movePillBackground())
  resizeObserver.observe(container.value!)

  if (shouldShow) {
    addTooltip()
  }
})

onBeforeUnmount(() => {
  if (resizeObserver !== null) {
    resizeObserver.disconnect()
    resizeObserver = null
  }
})
</script>

<template>
  <div ref="container"
       class="distribution-toggle"
       v-bind="attrs"
       :class="{
         [`distribution-toggle--variant-${props.variant}`]: props.variant !== 'default',
         [`distribution-toggle--size-${props.size}`]: true
       }"
  >
    <div v-if="onLocationIdentifier && distributionTypes.includes(DistributionType.OnLocation)"
         ref="onLocationPill"
         :data-dist-id="DistributionType.OnLocation"
         :data-test-id="`distribution-tab-${DistributionType.OnLocation}`"
         class="distribution-toggle__option distribution-toggle__option--active">
      {{ t('terms.on-location') }}: {{ onLocationIdentifier }}
    </div>
    <template v-else>
      <div v-if="props.distributionTypes.includes(DistributionType.TakeAway)"
           ref="takeAwayPill"
           :data-dist-id="DistributionType.TakeAway"
           :data-test-id="`distribution-tab-${DistributionType.TakeAway}`"
           class="distribution-toggle__option"
           :class="{ 'distribution-toggle__option--active': distributionType === DistributionType.TakeAway }"
           :disabled="distributionType === DistributionType.TakeAway"
           @click="changeDistribution($event, DistributionType.TakeAway)"
      >
        <template v-if="doesDeliveriesAndTakeAways">
          {{ t('distribution-toggle.takeaway') }}
        </template>
        <template v-else>
          {{ t('distribution-toggle.takeaway-only') }}
        </template>
      </div>

      <div v-if="props.distributionTypes.includes(DistributionType.Delivery)"
           ref="deliveryPill"
           :data-dist-id="DistributionType.Delivery"
           :data-test-id="`distribution-tab-${DistributionType.Delivery}`"
           class="distribution-toggle__option"
           :class="{ 'distribution-toggle__option--active': distributionType === DistributionType.Delivery }"
           :disabled="distributionType === DistributionType.Delivery"
           @click="changeDistribution($event, DistributionType.Delivery)"
      >
        <template v-if="doesDeliveriesAndTakeAways">
          {{ t('terms.delivery') }}
        </template>
        <template v-else>
          {{ t('terms.delivery-only') }}
        </template>

      </div>

    </template>

    <div ref="pillBackground"
         class="distribution-toggle__moving-pill"
    />
  </div>

  <div v-if="showTooltip" class="distribution-tooltip" ref="distTooltip" :class="{'left' : distributionType === DistributionType.TakeAway, 'right' : distributionType === DistributionType.Delivery}">
     {{ t('messages.check-if-you-want', {type: distributionType === DistributionType.Delivery ? 'bezorgen' : 'afhalen'}) }}
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.distribution-toggle {
  $self: &;
  display: flex;
  position: relative;
  background-color: #E9ECF2;
  border-radius: 999999px;
  padding: 0.25rem 0.25rem;
  cursor: pointer;
  max-width: max-content;
  user-select: none;
  transition: all 0.2s;

  &__option,
  &__moving-pill {
    border-radius: 999999px;
    z-index: 2;
    position: relative;
  }

  &__option {
    font-size: 0.875rem;
    padding: 0.25rem 1rem;

    &--active {
      font-weight: 700;

    }

    &.glow,&.glowout{
      //animation for fading in the border
      &:before {
        content: '';
        z-index: 1;
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        border: 0.5rem solid;
        border-radius: 9999px;
        border-color: rgba(255, 95, 89, 0.3);
        width: 100%;
        height: 100%;

        animation: glow 1.5s ease-in-out infinite alternate;
        @keyframes glow {
          0% {
            border-color: rgba(255, 95, 89, 0.4);
          }
          100% {
            border-color: rgba(255, 95, 89, 0);;
          }
        }
      }
    }

    &.glowout{
      &:before{
        //run animation once
        animation: glowOut 2s forwards;
        @keyframes glowOut {
          0% {
            border-color: rgba(255, 95, 89, 0.4);
          }
          100% {
            border-color: rgba(255, 95, 89, 0);
          }
        }
      }
    }
  }

  &__moving-pill {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 1px 2px 1px rgba(36, 46, 74, 0.1);
    transition: all 0.25s;
    z-index: 1;
    height: calc(100% - 0.5rem);
    width: 50%;
  }

  &--size-lg {
    padding: 0.4rem;

    #{$self}__option {
      font-size: 0.875rem;
      padding: 0.55rem 1.5rem;
    }
  }

  &--variant-light {
    background-color: #F8F8FA;

    &:hover {
      background-color: #E9ECF2;
    }
  }
}

.distribution-tooltip {
  position:absolute;
  z-index:500;
  width: auto;

  &.hidden{
    display:none;
  }
  &.fadeout{
    //run animation once
    animation: hide 2s forwards;
    @keyframes hide {
      0% {
        opacity: 100%;
      }
      100% {
        opacity: 0;

      }
    }
  }

  margin-top:1rem;

  border: 1px solid #E9ECF2;

  background-color:white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(36, 46, 74, 0.1);
  font-size: 0.875rem;

  font-weight:bold;

  //add a white arrow on top that also has shadow
  &:before {
      $s: 12px;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: -1.4rem;

      border-style: solid;
      border-width: $s;
      border-color: transparent  transparent white transparent;
  }

  &.left{
    &:before{
      left: 1.5rem;
    }
  }

  &.right{
    &:before{
      left: 7rem;
    }
  }

}
</style>
