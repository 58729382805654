<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDebounceFn } from '@vueuse/core'
import Headline from '@/components/partials/Headline.vue'
import ModalNuevo from '@/components/Modals/ModalNuevo/ModalNuevo.vue'
import SavedLocations from '@/components/Locations/SavedLocations.vue'
import type { Location } from '@/types/Location'
import { useStore } from '@/stores'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import { useGoogleMaps } from '@/composables/googleMaps'
import Icon from '@/components/partials/Icon.vue'

const props = defineProps<{
  modelValue: boolean
  closable?: boolean
  error?: string | null
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'address', location: Location): void
  (e: 'closed'): void
}>()

const { t, locale } = useI18n()

const visible = computed({
  get: () => props.modelValue,
  set: val => emit('update:modelValue', val)
})

function selectLocation(location: Location) {
  emit('address', location)

  visible.value = false
}

const store = useStore()

const savedLocations = computed<Location[]>(() => store.getters['session/savedLocations'])

const storedLocation = computed<Location | null>(() => store.getters['session/location'])

const location = [
  'street',
  'houseNumber',
  'zipcode',
  'city',
].reduce((acc, key, index) => {
  if (storedLocation.value === null || storedLocation.value[key] === '') {
    return acc
  }

  if (index === 0) {
    return storedLocation.value[key]
  }

  return `${acc} ${storedLocation.value[key]}`
}, '')

type State = {
  address: string
  predictions: {
    description: string
    placeId: string
  }[]
}

const state = reactive<State>({
  address: storedLocation.value !== null ? location : '',
  predictions: [],
})

const { places, loader, geocode } = useGoogleMaps()

const l = loader()

const dutchZipCodeRegex = /\d{4}\s?[A-Za-z]{2}$/

const predictAddress = useDebounceFn(async () => {
  if (state.address === '') return

  // note: zipcode search only works for NL
  const type = state.address.match(dutchZipCodeRegex) && store.state.session.country === 'nl' ? 'geocode' : 'address'

  const { predictions } = await places(l, state.address, [ type ], locale.value, store.state.session.country)

  state.predictions = predictions.map(prediction => ({ description: prediction.description, placeId: prediction.place_id }))
}, 200)

async function choosePrediction(placeId: string) {
  const [ place ] = await geocode(l, { placeId })

  const { address_components, geometry: { location } } = place
  const street = address_components.find(comp => comp.types.includes('route'))
  const streetNumber = address_components.find(comp => comp.types.includes('street_number'))
  const postalCode = address_components.find(comp => comp.types.includes('postal_code'))
  const city = address_components.find(comp => comp.types.includes('locality'))
  const country = address_components.find(comp => comp.types.includes('country'))

  emit('address', {
    id: null,
    street: street?.long_name ?? '',
    houseNumber: streetNumber?.long_name ?? '',
    zipcode: postalCode?.long_name ?? '',
    city: city?.long_name ?? '',
    country: country?.short_name.toLowerCase() as 'nl' | 'de',
    coordinates: {
      lat: location.lat(),
      lng: location.lng(),
    },
    title: null,
  })

  state.predictions = []
}

function close() {
  visible.value = false

  state.predictions = []
  state.address = storedLocation.value !== null ? location : ''

  emit('closed')
}
</script>

<template>
  <ModalNuevo :closable="closable"
              :open="visible"
              @close="close"
              data-test-id="modal-address">
    <div class="modal-address">
      <Headline>
        <div class="modal-address__title modal-address__title--lg">
          {{ t('location-modal.provide-address') }}
        </div>
      </Headline>

      <TextInput v-model="state.address"
                 @input="predictAddress"
                 data-test-id="modal-address-input"
                 placeholder="Adres, bijvoorbeeld Goudestein 30"
                 :error="error" />

      <ul class="predictions" data-test-id="modal-address-predictions">
        <li class="prediction" v-for="location in state.predictions" :key="location.placeId" @click="choosePrediction(location.placeId)">
          <Icon name="pin" class="prediction__pin" /> {{ location.description }}
        </li>
      </ul>

      <div class="modal-address__addresses" v-if="savedLocations.length > 0">
        <SavedLocations :locations="savedLocations" @select-location="selectLocation" />
      </div>
    </div>
  </ModalNuevo>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.predictions {
  margin: 1rem 0 0 0;
  list-style-type: none;

  .prediction {
    display: flex;
    padding: 0.75rem 0.5rem;
    gap: 0.5rem;
    font-weight: 700;
    font-size: 1rem;

    &__pin {
      color: var(--color-neutral-secondary);
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(66deg, rgba(255,42,161,.1) 0%,rgba(255,157,5,.1) 100%);
    }
  }
}

.modal-address {
  // width: 100%;

  // @include lg-up {
  //   width: 35rem;
  // }

  &__title {
    font-weight: 700;
    margin-bottom: 1rem;

    &--lg {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
  }

  &__addresses {
    margin: 2rem 0;
  }
}
</style>
